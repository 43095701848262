<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title flex-column" style="justify-content: space-evenly">
        <h3 class="card-label font-weight-bolder text-dark">
          Selected Talents
        </h3>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="tab-content">
            <!--begin::Table-->
            <div v-if="talents && talents.length" class="table-responsive mb-0">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr class="text-left">
                    <th>The talent's name</th>
                    <th>Role</th>
                    <th>Qualified</th>
                    <th>Scheduled on</th>
                    <th>Interview Date</th>
                    <th>Clickup Task ID</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class=""
                    v-for="talent in talents"
                    :key="talent.id"
                    :style="talents.length == 1 ? 'height: 7rem;' : ''"
                  >
                    <td :title="`${talent.talent.full_name}`">
                      <a :href="talent.talent.dashboard_url" target="_blank">{{
                        talent.talent.full_name
                      }}</a>
                    </td>
                    <td>{{ talent.talent.role?.name }}</td>
                    <td>
                      {{ talent.talent.is_qualified ? "Yes" : "No" }}
                    </td>

                    <td>
                      <span class="text-center justify-content-center d-flex">
                        {{
                          talent.created_at
                            ? formatDateTime(talent.created_at)
                            : "-"
                        }}
                      </span>
                    </td>
                    <td>
                      <span class="text-center justify-content-center d-flex">
                        {{
                          talent.interview?.meeting_start_time
                            ? formatInterviewDateTime(
                                talent.interview.meeting_start_time
                              ).datetime
                            : "-"
                        }}
                      </span>
                    </td>

                    <td :style="talents.length == 1 ? 'height: 7rem;' : ''">
                      <div class="d-flex justify-content-center">
                        <div
                          v-if="talent.required_resource?.clickup_task_url"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <a
                            :href="talent?.required_resource?.clickup_task_url"
                            target="_blank"
                            v-b-tooltip.hover="talent?.required_resource?.name"
                          >
                            <img
                              class="d-flex align-items-start"
                              style="height: 2.5rem; width: 2.5rem"
                              src="@/assets/images/clickup.png"
                              alt="clickup"
                            />
                          </a>
                        </div>
                        <div
                          v-if="
                            !talent.required_resource?.clickup_task_url &&
                            checkSelectionTalentsIds(talent.id)
                          "
                        >
                          in progress...
                        </div>
                        <div
                          v-if="
                            !talent.required_resource?.clickup_task_url &&
                            !checkSelectionTalentsIds(talent.id) &&
                            talent.status.id !== 'talent_pending_update' &&
                            talent.status.id !== 'talent_unavailable' &&
                            talent.status.id !== 'client_pending_update' &&
                            talent.status.id !== 'canceled'
                          "
                          class="d-flex justify-content-center align-items-center"
                        >
                          <v-btn
                            class="ma-2 font-weight-bolder"
                            color="success"
                            @click="resyncRequieredResources(talent)"
                          >
                            Auto-Match
                          </v-btn>
                        </div>
                        <div
                          v-if="
                            !talent.required_resource?.clickup_task_url &&
                            !checkSelectionTalentsIds(talent.id) &&
                            (talent.status.id == 'talent_pending_update' ||
                              talent.status.id == 'talent_unavailable' ||
                              talent.status.id == 'client_pending_update' ||
                              talent.status.id == 'canceled')
                          "
                          class="d-flex justify-content-center align-items-center"
                        >
                          -
                        </div>
                        <v-select
                          v-if="false"
                          style="width: 250px"
                          class="mr-2"
                          label="Clickup task ID"
                          :items="requiredResources"
                          item-text="name"
                          item-value="id"
                          deletable-chips
                          dense
                          hide-details
                          outlined
                          v-model="talent.required_resource"
                          @change="statusDropdownChanged(talent)"
                        />
                        <b-button
                          v-if="
                            !checkIdRequiredResourceExists(
                              talent.talent.role?.name
                            ) && false
                          "
                          size="sm"
                          class="mr-5"
                          variant="outline-info"
                          @click="addRequiredResource(talent.id)"
                          ><i class="fas fa-plus px-0 py-0"></i
                        ></b-button>
                      </div>
                      <p v-if="false" class="form__error-label">
                        Please select a clickup task ID
                      </p>
                    </td>

                    <td>
                      <div
                        class="d-flex"
                        :style="
                          talents.length == 1
                            ? 'height: 7rem; align-items: center;'
                            : ''
                        "
                      >
                        <div
                          v-b-tooltip.hover.top="
                            `${
                              interviewStatusesList.find(
                                (i) => i.id == talent.status.id
                              ).name
                            }`
                          "
                        >
                          <v-select
                            style="width: 250px"
                            label="Status"
                            :items="interviewStatusesList"
                            item-text="name"
                            item-value="id"
                            dense
                            deletable-chips
                            hide-details
                            outlined
                            :disabled="
                              talent.status.id == 'talent_unavailable' ||
                              talent.status.id == 'talent_pending_update' ||
                              talent.status.id == 'client_pending_update' ||
                              talent.status.id == 'talent_pending' ||
                              talent.status.id == 'client_pending' ||
                              talent.status.id == 'canceled'
                            "
                            v-model="talent.status"
                            :class="getStatusClass(talent.status)"
                            @change="statusDropdownChanged(talent)"
                            return-object
                          />
                        </div>
                        <div
                          class="d-flex justify-content-center ms-3 align-items-center"
                          style="height: 2rem"
                        >
                          <a
                            v-if="
                              talent.status.id == 'canceled' &&
                              talent.cancelation_reason
                            "
                            class="navi-link d-flex justify-content-center mt-3"
                            @click.stop="
                              showRejectionReasonPopup(
                                talent.cancelation_reason,
                                'canceled'
                              )
                            "
                          >
                            <small
                              style="white-space: nowrap; font-weight: bold"
                            >
                              View Cancellation reason
                            </small>
                          </a>
                          <a
                            v-if="
                              talent.status.id == 'rejected' &&
                              talent.rejection_reason
                            "
                            class="navi-link d-flex justify-content-center mt-3"
                            @click.stop="
                              showRejectionReasonPopup(talent.rejection_reason)
                            "
                          >
                            <small
                              style="white-space: nowrap; font-weight: bold"
                            >
                              Rejection Reason
                            </small>
                          </a>
                          <a
                            v-if="
                              ['talent_pending', 'client_pending'].includes(
                                talent.status.id
                              ) && hideAllActions
                            "
                            class="navi-link d-flex justify-content-center mt-3"
                            @click.stop="showTimesSlotsModal(talent)"
                          >
                            <small
                              style="white-space: nowrap; font-weight: bold"
                            >
                              View proposed slots
                            </small>
                          </a>
                        </div>
                        <div
                          class="d-flex justify-content-center ms-3 align-items-center"
                          style="height: 3rem"
                          v-if="
                            talent.feedback_blocked_reason ||
                            talent.acceptance_blocked_reason
                          "
                        >
                          <small
                            style="
                              white-space: nowrap;
                              font-weight: bold;
                              cursor: pointer;
                              color: red;
                            "
                            @click.stop="
                              showFeedbackPopup(
                                talent.feedback_blocked_reason ||
                                  talent.acceptance_blocked_reason
                              )
                            "
                          >
                            Unable to send the email
                          </small>
                        </div>

                        <div
                          v-if="talent.statusChanged && !hideAllActions"
                          class="d-flex justify-content-center"
                        >
                          <button
                            class="btn btn-info btn-sm mr-2"
                            @click="statusChanged(talent)"
                          >
                            Confirm
                          </button>
                          <button
                            class="btn btn-light btn-sm"
                            @click="cancelStatusChange(talent)"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5
              class="my-5 text-muted text-center d-flex justify-content-center align-items-center"
              style="min-height: 40rem"
              v-else
            >
              No talents Yet
            </h5>
            <div class="pagination-container">
              <p
                v-if="talents && talents.length > 0"
                class="pagination-container__desc"
              >
                {{
                  $t("showing_results", {
                    length: talents.length,
                    total: talentsMeta.total,
                  })
                }}
              </p>
              <b-pagination
                v-if="talentsMeta && talentsMeta.last_page > 1"
                v-model="page"
                :total-rows="talentsMeta.total"
                :per-page="talentsMeta.per_page"
                align="center"
                last-number
                first-number
              ></b-pagination>
            </div>

            <!--end::Table-->
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showInterviewProposedSlotsModal"
      persistent
      max-width="550px"
      transition="dialog-bottom-transition"
    >
      <v-card v-if="activeTalent && activeTalent.id" class="p-5 p-md-8">
        <ModalHeading modalTitle="View proposed slots" />
        <div class="dialog-card-content">
          <v-radio-group v-model="selectedInterviewSlot">
            <div
              class="d-flex align-center pa-4"
              v-for="slot in activeTalent.interview_slots"
              :key="slot.id"
            >
              <v-radio
                :id="`slot_${slot.id}`"
                :value="slot.id"
                :disabled="!slot.is_active"
                class="pe-2"
              ></v-radio>
              <label
                :for="`slot_${slot.id}`"
                :class="{
                  disabled: !slot.is_active,
                }"
                :title="`${
                  !slot.is_active ? 'Interview time passed' : 'Interview Slot'
                }`"
              >
                <v-card
                  v-if="slot.id !== 'custom'"
                  class="mx-auto cursor-pointer rounded-lg"
                  width="400"
                >
                  <template v-slot:title>
                    <span class="font-weight-black">{{
                      formatDateTime(slot.start)
                    }}</span>
                  </template>

                  <v-card-text class="bg-surface-light pt-4">
                    <div class="d-flex justify-content-around pa-4">
                      <div class="d-flex flex-column justify-content-center">
                        <b class="text-h5 d-flex justify-content-center">
                          <v-icon class="mr-1">event</v-icon>
                        </b>
                        <span class="d-block mt-2 ms-1">
                          <b>{{ formatInterviewDateTime(slot.start).date }}</b>
                        </span>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <b class="text-h5 d-flex justify-content-center">
                          <v-icon class="mr-1">mdi-clock</v-icon>
                        </b>
                        <span class="d-block mt-2 ms-1">
                          <b>{{ formatInterviewDateTime(slot.start).time }}</b>
                        </span>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <b class="text-h5 d-flex justify-content-center">
                          <v-icon class="mr-1">mdi-account</v-icon>
                        </b>
                        <span class="d-block mt-2 ms-1">
                          <b>{{ slot.owner_type }}</b>
                        </span>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </label>
            </div>
          </v-radio-group>
        </div>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="confirmInterviewSlot(activeTalent)"
            :disabled="!selectedInterviewSlot"
            :class="{ 'cursor-not-allowed': !selectedInterviewSlot }"
          >
            Confirm
          </v-btn>
          <v-btn @click="showInterviewProposedSlotsModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ModalHeading,
  },
  props: {
    selectedProject: {
      type: Number,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 8,
      hideAllActions: true,
      showError: false,
      activeTalent: null,
      selectedInterviewSlot: null,
      showInterviewProposedSlotsModal: false,
      talentToShowError: null,
      query: {},
      talentsPreviousValue: null,
    };
  },
  computed: {
    ...mapGetters({
      talents: "clients/getSelectedTalentsList",
      talentsMeta: "clients/getSelectedTalentsMeta",
      interviewStatuses: "clients/getTalentInterviewStatus",
      requiredResources: "clients/getProjectRequiredResources",
    }),
    interviewStatusesList() {
      return this.interviewStatuses.map((status) => {
        if (status.id === "talent_pending_update") {
          return {
            ...status,
            name: "Pending talent confirmation",
          };
        } else if (status.id === "client_pending_update") {
          return {
            ...status,
            name: "Pending client time slots",
          };
        } else if (status.id === "talent_pending") {
          return {
            ...status,
            name: "Pending talent meeting confirmation",
          };
        } else if (status.id === "client_pending") {
          return {
            ...status,
            name: "Pending client confirmation on new slots",
          };
        }
        return status;
      });
    },
    projectId() {
      return this.$route.params.project_id;
    },
    talentsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
        ...this.query,
      };
    },
  },
  watch: {
    async selectedProject() {
      this.page = 1;
      const payload = {
        project_id: this.selectedProject,
        talentsQuery: this.talentsQuery,
      };
      await this.getSelectedTalentsList(payload).then(() => {
        this.talentsPreviousValue = this.talents.map((talent) => ({
          ...talent,
        }));
      });
      // await this.getProjectRequiredResources(payload);
    },
    async page() {
      const payload = {
        project_id: this.projectId,
        talentsQuery: this.talentsQuery,
      };
      await this.getSelectedTalentsList(payload).then(() => {
        this.talentsPreviousValue = this.talents.map((talent) => ({
          ...talent,
        }));
      });
    },
  },
  async mounted() {
    this.getSelectedTalents();
    this.getTalentStatuses();
  },
  methods: {
    ...mapActions({
      getSelectedTalentsList: "clients/getSelectedTalentsList",
      setSelectedInterviewSlot: "clients/setSelectedInterviewSlot",
      getProjectRequiredResources: "clients/getProjectRequiredResources",
      getTalentStatuses: "clients/getTalentStatuses",
      createRequiredResource: "clients/createRequiredResource",
      resyncMatchTalentWithRequieredResources:
        "clients/resyncMatchTalentWithRequieredResources",
    }),
    itsBeforeNow(date) {
      return dayjs(date).isBefore(dayjs());
    },
    async confirmInterviewSlot(talent) {
      const selectedSlot = this.selectedInterviewSlot;
      console.log(talent);
      const payload = {
        selection_id: talent.id,
        interview_slot_id: selectedSlot !== "custom" ? selectedSlot : null,
      };

      await this.setSelectedInterviewSlot(payload).then(() => {
        this.showInterviewProposedSlotsModal = false;
        this.getSelectedTalents();
      });
    },
    async getSelectedTalents() {
      const payload = {
        project_id: this.projectId,
        talentsQuery: this.talentsQuery,
      };
      await this.getSelectedTalentsList(payload).then(() => {
        this.talentsPreviousValue = this.talents.map((talent) => ({
          ...talent,
        }));
      });

      let selectionPanddingTalentsIds =
        JSON.parse(localStorage.getItem("selectionTalentsIds")) || [];

      this.talents.map((talent) => {
        if (
          talent.required_resource &&
          selectionPanddingTalentsIds.includes(talent.id)
        ) {
          selectionPanddingTalentsIds = selectionPanddingTalentsIds.filter(
            (id) => id !== talent.id
          );
        }
      });

      if (selectionPanddingTalentsIds.length > 0) {
        setTimeout(() => {
          this.getSelectedTalents();
        }, 5000);
      }

      localStorage.setItem(
        "selectionTalentsIds",
        JSON.stringify(selectionPanddingTalentsIds)
      );
    },
    checkSelectionTalentsIds(talentId) {
      let selectionTalentsIds =
        JSON.parse(localStorage.getItem("selectionTalentsIds")) || [];
      return selectionTalentsIds.includes(talentId);
    },
    showTimesSlotsModal(selectedTalent) {
      this.activeTalent = null;
      this.activeTalent = this.talents.find(
        (talent) => talent.id === selectedTalent.id
      );
      if (!this.activeTalent.id) return;

      if (this.activeTalent.status.id == "talent_pending")
        this.selectedInterviewSlot = this.activeTalent.interview_slots[0].id;
      else if (this.activeTalent.status.id == "talent_unavailable")
        this.selectedInterviewSlot = "custom";

      this.showInterviewProposedSlotsModal = true;
    },
    showRejectionReasonPopup(reason, status) {
      this.$bvModal.msgBoxOk(reason, {
        title:
          status == "canceled" ? "Cancellation reason" : "Rejection Reason",
        size: "md",
        buttonSize: "md",
        okVariant: "primary",
        headerClass: "p-4 border-bottom-1",
        footerClass: "p-2 border-top-0",
        bodyClass: "text-break",
        centered: true,
      });
    },
    showFeedbackPopup(reason) {
      this.$bvModal.msgBoxOk(
        reason.replace("Required resource is missing fields: ", ""),
        {
          title: "Required Resource Missing Fields",
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          headerClass: "p-4 border-bottom-1",
          footerClass: "p-2 border-top-0",
          bodyClass: "text-break",
          centered: true,
        }
      );
    },
    resyncRequieredResources(talent) {
      this.resyncMatchTalentWithRequieredResources({
        project_id: this.projectId,
        selection: talent.id,
        talentsQuery: this.talentsQuery,
      }).then(() => {
        let selectionTalentsIds =
          JSON.parse(localStorage.getItem("selectionTalentsIds")) || [];
        if (!selectionTalentsIds.includes(talent.id)) {
          selectionTalentsIds.push(talent.id);
          localStorage.setItem(
            "selectionTalentsIds",
            JSON.stringify(selectionTalentsIds)
          );
        }

        if (selectionTalentsIds.length > 0) {
          this.getSelectedTalents();
        }
      });
    },
    getStatusClass(status) {
      const statusClasses = {
        scheduled: "darkblue-status-selected-talent",
        accepted: "darkgreen-status-selected-talent",
        rejected: "darkturquoise-status-selected-talent",
        talent_no_show: "darkred-status-selected-talent",
        client_no_show: "darkred-status-selected-talent",
        completed: "darkorange-status-selected-talent",
        canceled: "darkred-status-selected-talent",
        expired: "crimson-status-selected-talent",
        hired: "tealblue-status-selected-talent",
      };
      return statusClasses[status.id ? status.id : status] || "";
    },
    checkIdRequiredResourceExists(roleName) {
      return this.requiredResources.some(
        (resource) => resource.name?.split(" | ")[1] === roleName
      );
    },
    formatDateTime(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },

    formatInterviewDateTime(date) {
      const formattedDate = dayjs(date).format("DD/MM/YYYY");
      const formattedTime = dayjs(date).format("hh:mm A");
      const datetime = `${formattedDate} at ${formattedTime}`;
      return { date: formattedDate, time: formattedTime, datetime };
    },
    statusDropdownChanged(talent) {
      if (talent.talent) {
        talent.statusChanged = true;
        this.hideAllActions = false;
      } else {
        this.showError = true;
        this.talentToShowError = talent;
      }
    },
    cancelStatusChange(talent) {
      const talentPrevious = this.talentsPreviousValue.find(
        (t) => t.id === talent.id
      );
      const currentTalent = this.talents.find((t) => t.id === talent.id);

      currentTalent.status = talentPrevious.status;

      talent.statusChanged = false;
      this.hideAllActions = true;
    },
    async statusChanged(talent) {
      const { id, status } = talent;
      await this.$store
        .dispatch("clients/updateTalentStatus", {
          selection: id,
          status: status.id ? status.id : status,
          project_id: this.projectId,
          talentsQuery: this.talentsQuery,
        })
        .then(() => {
          this.getSelectedTalents();
        });

      talent.statusChanged = false;
      this.showError = false;
      this.talentToShowError = null;
    },
    addRequiredResource(talentId) {
      this.createRequiredResource({
        project_id: this.projectId,
        selection: talentId,
        talentsQuery: this.talentsQuery,
      });
    },
  },
};
</script>

<style>
.darkblue-status-selected-talent .v-select__selections {
  color: darkblue !important;
}

.darkgreen-status-selected-talent .v-select__selections {
  color: darkgreen !important;
}

.darkturquoise-status-selected-talent .v-select__selections {
  color: #71ec71 !important;
}

.darkred-status-selected-talent .v-select__selections {
  color: darkred !important;
}

.darkorange-status-selected-talent .v-select__selections {
  color: darkorange !important;
}

.darkturquoise-status-selected-talent .v-select__selections {
  color: #71ec71 !important;
}

.crimson-status-selected-talent .v-select__selections {
  color: crimson !important;
}

.tealblue-status-selected-talent .v-select__selections {
  color: #006400 !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
